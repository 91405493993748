import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

import { SeoProps } from './models';

const Seo: FC<SeoProps> = ({ lang, seo, openGraph, pageTitle, pageUrl }) => {
  const { siteSettings, brandSettings } = useStaticQuery(graphql`
    query SettingsQuery {
      siteSettings {
        siteUrl
        siteName
        lang
        googleSiteVerficationNumber
        defaultOpenGraphImage {
          url
          fallbackUrl
        }
      }
      brandSettings {
        brandName
      }
    }
  `);

  const {
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
    seoNoIndex,
    seoExternalHreflangs,
    seoCanonicalUrl,
  } = seo;
  const { openGraphTitle, openGraphDescription, openGraphImage, openGraphType } = openGraph;

  const siteTitle = siteSettings.siteName || '';
  const googleSiteVerficationNumber = siteSettings.googleSiteVerficationNumber || '';
  const titleTemplate = seoMetaTitle === siteTitle ? '%s' : `%s | ${siteTitle}`;
  const siteAuthor = brandSettings.brandName;
  const siteLang = lang || siteSettings.lang;
  const ogTitle = openGraphTitle || seoMetaTitle || pageTitle;
  const ogDescription = openGraphDescription || seoMetaDescription;
  const ogImage = openGraphImage ? openGraphImage.url : siteSettings.defaultOpenGraphImage.url;
  const googleIndex = seoNoIndex ? 'noindex,nofollow' : 'index,follow';

  const meta = [
    {
      name: 'description',
      content: seoMetaDescription,
    },
    {
      property: 'og:title',
      content: ogTitle,
    },
    {
      property: 'og:description',
      content: ogDescription,
    },
    {
      property: 'og:type',
      content: openGraphType || 'website',
    },
    {
      property: 'og:image',
      content: ogImage,
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: siteAuthor,
    },
    {
      name: 'twitter:title',
      content: ogTitle,
    },
    {
      name: 'twitter:description',
      content: ogDescription,
    },
    {
      name: 'google-site-verification',
      content: googleSiteVerficationNumber,
    },
    {
      name: 'robots',
      content: googleIndex,
    },
    {
      name: 'googlebot',
      content: googleIndex,
    },
    ...(seoMetaKeywords?.length
      ? [
          {
            name: 'keywords',
            content: seoMetaKeywords.join(', '),
          },
        ]
      : []),
  ];

  const pageAbsoluteUrl = `${siteSettings.siteUrl}${pageUrl}`;

  const defaultHreflangs = [
    {
      key: siteSettings.lang,
      value: pageAbsoluteUrl,
    },
    {
      key: 'x-default',
      value: pageAbsoluteUrl,
    },
  ];

  seoExternalHreflangs.push(...defaultHreflangs);

  const canonicalUrl = seoCanonicalUrl
    ? `${siteSettings.siteUrl}${seoCanonicalUrl}`
    : pageAbsoluteUrl;

  const link = [
    ...(seoExternalHreflangs?.length
      ? seoExternalHreflangs?.map((item) => ({
          rel: 'alternate',
          href: item.value,
          hreflang: item.key,
        }))
      : []),
    {
      rel: 'canonical',
      href: canonicalUrl,
    },
  ];

  return (
    <Helmet
      htmlAttributes={{ lang: siteLang }}
      {...{ title: pageTitle, seoMetaTitle, titleTemplate, meta, link }}
    />
  );
};

export default Seo;
